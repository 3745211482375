import axios from '../../util/axios';
import api from '../../util/api';
const default_state = {
  goodsTypeList: [],
}
const state = {
  goodsTypeList: [],
};
const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for(let i in data){
      state[i] = data[i]
    }
  },
  getTypeList(state, data) {
    state.goodsTypeList = data;
  },
};

const actions = {
  getTypeList({ commit },data= {}) {
    axios.post(api.goods.goodsClass,data).then((res) => {
      if (res.code == 0) {
        let data = res.result;
        for (let i in data) {
          data[i].grade = '一级';
          data[i].label = data[i].name;
          data[i].value = data[i].id;
          data[i].index = i;
          for (let y in data[i]._child) {
            data[i]._child[y].label = data[i]._child[y].name;
            data[i]._child[y].value = data[i]._child[y].id;
            data[i]._child[y].grade = '二级';
          }
          data[i].children = data[i]._child;
          let children_id = []
          for(let y in data[i].children){
            children_id.push(data[i].children[y].id)
          }
          data[i].children_id = children_id
        }
        commit("getTypeList",data)
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
